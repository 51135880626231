import Amplify, { Hub } from "@aws-amplify/core";
import Analytics from '@aws-amplify/analytics';
// import {  Cache/*, Hub , Logger,API, graphqlOperation,*/ } from "aws-amplify"
import PropTypes from 'prop-types'
import React, { useContext, useState, useEffect } from 'react'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'

import { css } from "@emotion/core"
import styled from '@emotion/styled'

import { graphql, useStaticQuery, Link, navigate } from 'gatsby'

import Image from 'react-bootstrap/Image'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
// import { navigate } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";


import awsconfig from "../../../aws-exports";
import { AppsContext } from '../../../AppsProvider'
Amplify.configure(awsconfig);

// a.subnav_link:link {
//   text-decoration: none; }

// a.subnav_link:hover {
//   cursor: pointer;
//   color: gray; }

// a.subnav_link:visited {
//   color: red; }

// a.subnav_link:active {
//   color: red; }

// const MenuLink = props => {
//   // docs: https://reach.tech/router/api/Link
//   const linkStyle = css`
//     margin-right: 1rem;
//     justify-self: flex-end;
//     color: #f8f8f8;
//     text-decoration: none;
//     &:hover {
//       color: white;
//     }
//     &:after {
//       content: "";
//       display: block;
//       width: 0%;
//       padding-top: 0px;
//       border-bottom: 1px solid #f8f8f8;
//       transition: all 0.3s ease-in;
//     }
//     &:hover:after {
//       width: 100%;
//     }
//   `
//   const activeLinkStyle = css`
//     margin-right: 1rem;
//     justify-self: flex-end;
//     color: white;
//     text-decoration: none;
//     &:after {
//       content: "";
//       display: block;
//       width: 100%;
//       padding-top: 0px;
//       border-bottom: 1px solid #f8f8f8;
//       transition: all 0.3s ease-in;
//     }
//   `
//   const isActive = ({ isCurrent }) => {
//     // toggle for active link
//     // return isCurrent ? console.log("current") : console.log("not current")
//     return isCurrent ? { style: { activeLinkStyle } } : { style: { linkStyle } }
//   }
//   return <Link style={linkStyle} activeStyle={activeLinkStyle} {...props} />
// }

const light = {
  main: "#2a2a2a",
  secondary: "white",
  link: "white",
  brand: "rebeccapurple",
  body: "white",
}

const dark = {
  main: "white",
  secondary: "#2a2a2a",
  link: "white",
  brand: "palevioletred",
  body: "#2a2a2a",
}

const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"
const activeLinkColor = "#1b2f63"
const shotwireGray = '#e9e4e3'

const linkStyle = {
  color: colorWhite50,
  padding: '.5rem 0',
  margin: '0 .4rem 0 0',
  boxShadow: 'none',
  textDecoration: 'none',
  ':hover': '#000'
}

const linkStyleDark = {
  color: colorBlack80,
  margin: '0 .5rem .5rem 1.5rem',
  texAlign: 'center',
  boxShadow: 'none',
  textDecoration: 'none',
  '&:hover': '#000'
}

const StyledLink = styled(props => <Link {...props} />)`
  padding: .5rem .5rem .5rem 0;
  margin: 0 0 0 0;
  color: ${colorBlack50};
  textDecoration: none;
  &:hover {
    color: ${colorBlack80};
  }
`;

const StyledSubLink = styled(props => <Link {...props} />)`
  display: block;
  padding: 0 .5rem 0 .5rem;
  margin: 0 0 0 0;
  color: ${colorBlack50};
  textDecoration: none;
  &:hover {
    
    color: #000;
  }
`;

const StyledNavbar = styled(Navbar)`
  display: flex;
  min-height: 5rem;
  font-size: 1.2rem;
  background-color: ${shotwireGray};
`

const StyledBrand = styled(Navbar.Brand)`
  line-height: 31px;

  img {
    margin-right: 1rem;
  }
`

const propTypes = {
  activePage: PropTypes.string
}

let searchform = {}, 
    activePage = 1, 
    currentCategory = '',
    cart = []


function NavMain() {

  const appsContext = React.useContext(AppsContext)
  
  
    const handleHubDispatch = () => {
      Hub.dispatch(
          'auth', 
          { 
              event: 'test', 
              data: {color:'blue'}, 
              message:'test auth dispatch' 
      });
    }

  const handleMenuClick = (event, category) => {
    // event.preventDefault();
    if (event){ 
      if(category == '/'){
          event.preventDefault();
          navigate(
              "/",
              // {
              //   state: { searchform },
              // }
            )
      }else if(category == 'Login'){
        // console.log('login.')
        // Hub.dispatch(
        //     'auth', 
        //     { 
        //         event: 'test', 
        //         data: {color:'blue'}, 
        //         message:'test auth dispatch' 
        // });
      }else{
        // event.preventDefault();
        // console.log('props.location.pathname:', props.location.pathname)
          // console.log('clicked menu link:', event.target)
          // currentCategory = category
          // console.log('currentCategory:', currentCategory)
          // appsContext.dispatch({ type: "NEWCATEGORY",  currentCategory});
          // appsContext.dispatch({ type: "PAGETOKENS", nextToken: null })
        
          // navigate(
          //     category,
          //     // {
          //     //   state: { searchform },
          //     // }
          //   )

          Analytics.record({
                name: 'MAINMENU', 
                // Attribute values must be strings
                attributes: { 
                  category: category,  
                }
              });
      }
        
      }
  }

  const handleInputChange = (event) => {
    if (event){ 
      event.preventDefault();
        // console.log('search input change event:', event.target.value)
      searchform.terms = event.target.value
      appsContext.dispatch({ type: "SEARCH", searchform: {...appsContext.state.searchform, ...searchform} });
    }
  }
  // const handleMetaChange = (e) => {
  //   console.log('e.target.id:',e.target.id)
  //   console.log('e.target.value:',e.target.value)
  //   const metaForm = {}
  //   metaForm[e.target.id] = e.target.value
  //   console.log('metaForm::', metaForm)
  //   appsContext.dispatch({type:'METAFORM', metaForm: {...appsContext.state.metaForm, ...metaForm} })
  // }

  const submitSearch = (event) => {
    event.preventDefault();

  // console.log('appsContext.state.searchform:', appsContext.state.searchform)
    if (event){ 
      if(searchform.terms=='' || typeof searchform.terms == 'undefined'){
        console.log('no search terms.')
        appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition:'-10px', title:"Error", body: "SEARCH TERMS REQUIRED!" }})
        return('')
      }
      // console.log('clicked search event.target.href:', event.target.href)
      // console.log('clicked search event.target.name:', event.target.name)   
      searchform.category = event.target.name
      searchform.time = new Date().getTime()

      // console.log('window.location.pathname:',window.location.pathname)
      if(window.location.pathname == '/Search'){
        // console.log('on search page...', searchform)
        appsContext.dispatch({ type: "SEARCH", searchform });
      }else{
        appsContext.dispatch({ type: "SEARCH", searchform });
        navigate(
              "/Search",
              // {
              //   state: { searchform },
              // }
            )
      }
  }
}

  const { setting } = useStaticQuery(graphql`
    query {
      setting {
        url
        organization
        iconName
      }
    }
  `)
/*
<Nav.Link href="/">Home</Nav.Link>
            <Link style={{ color : `inherit`, textDecoration: `none` }} to="/CelebrityCandids">Home</Link>
            <Link to="/">Home</Link>
            <Nav.Link href="#news">News</Nav.Link>
            <NavDropdown title="Entertainment" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.2">Red Carpet Events</NavDropdown.Item>
              <NavDropdown.Item href="/CelebrityCandids">Celebrity Candids</NavDropdown.Item>           
            </NavDropdown>
            <Nav.Link href="#sports">Sports</Nav.Link>
            <Nav.Link href="#music">Music</Nav.Link>
            <Nav.Link href="#nature">Nature</Nav.Link>
            <Nav.Link href="#travel">Travel</Nav.Link>
            <Nav.Link href="#arts">Arts</Nav.Link>
            <NavDropdown title="Help" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Pricing</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Contact Us</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.4">Get Alerts</NavDropdown.Item>
            </NavDropdown>

<Link to="/" 
                  style={{ linkStyle }} 
                  className={{ dark }}
                  activeStyle={{ textDecoration: `none` }}>
                  Home
            </Link>
*/

const Nothing = () => {
  return(false)
}

useEffect(() => {
    let isMounted = true
    // console.log('shopCart:', appsContext.state.shopCart)

    // don't init cache here. breaks shit.
    // cart = Cache.getItem('shotwire-shopCart')
    // appsContext.dispatch({type:"SHOPCART", shopCart:cart}) 

    },[appsContext.state.shopCart]) 

  return (
    <StyledNavbar as="header" variant="light" role="banner" sticky="top" expand="xl">
      <Container>
        <StyledBrand href={setting.url} onClick={e => handleMenuClick(e, '/')}>
          <Image
            src={require(`../assets/shotwire-logo.png`)}
            alt="Shotwire Video"
            height={40}
            rounded
          />
        </StyledBrand>
        {

          (appsContext.state.shopCart !== null) ?
          (appsContext.state.shopCart.length > 0) ?
          <Link to="/ShopCart" sx={{
                            color: 'blue'
                            // marginRight: '0.5rem'
                          }}><FontAwesomeIcon
                          id="navShopCart"
                          icon={faShoppingCart}
                          size="lg"
                          sx={{
                            color: 'blue'
                            // marginRight: '0.5rem'
                          }}
                        />{appsContext.state.shopCart.length}</Link>:<span></span> : <span></span>
        }
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {
            // <StyledLink to="/Video" style={{ textDecoration: `none` }} activeStyle={{ color: activeLinkColor, textDecoration: `none` }} onClick={e => handleMenuClick(e, 'Video')}>VIDEO</StyledLink>
            }
{
            // <StyledLink to="/VideoLists" style={{ textDecoration: `none` }} activeStyle={{ color: activeLinkColor, textDecoration: `none` }} onClick={e => handleMenuClick(e, 'VideoLists')}>VIDEOS</StyledLink>
            }
            {
              // <StyledLink to="/Photo" style={{ textDecoration: `none` }} activeStyle={{ color: activeLinkColor, textDecoration: `none` }} onClick={e => handleMenuClick(e, 'Photo')}>PHOTO</StyledLink>
            }
            <NavDropdown title="PRODUCTS" id="basic-nav-dropdown">
              <StyledSubLink to="/Cloud" style={{ textDecoration: `none` }} activeStyle={{ textDecoration: `none` }} onClick={e => handleMenuClick(e, 'Cloud')}>Cloud Video Services</StyledSubLink>
              <StyledSubLink to="/Enterprise" style={{ textDecoration: `none` }} activeStyle={{ textDecoration: `none` }} onClick={e => handleMenuClick(e, 'Enterprise')}>Enterprise Video Platform</StyledSubLink>
              <StyledSubLink to="/VideoSubscriptions" style={{ textDecoration: `none` }} activeStyle={{ textDecoration: `none` }} onClick={e => handleMenuClick(e, 'VideoSubscriptions')}>Video Content License Subscriptions</StyledSubLink>
              
              {
                (appsContext.state.isAdmin)?

                  
                    <StyledSubLink to="/ControlCenter/" style={{ textDecoration: `none` }} activeStyle={{ textDecoration: `none` }} onClick={e => handleMenuClick(e, 'ControlCenter')}>My Control Center</StyledSubLink>                      
                :
                  null
                  // <StyledSubLink to="/Login" style={{ textDecoration: `none` }} activeStyle={{ textDecoration: `none` }} onClick={e => handleMenuClick(e, 'Login')}>User Login</StyledSubLink>                      

                  
              }
            </NavDropdown>
            <NavDropdown title="Help" id="basic-nav-dropdown">
              {
                (appsContext.state.isAdmin)?

                  
                    <StyledSubLink to="/Help" style={{ textDecoration: `none` }} activeStyle={{ textDecoration: `none` }} onClick={e => handleMenuClick(e, 'Support')}>ACCOUNT SUPPORT</StyledSubLink>                      
                :
                  <Nothing/>
                  
              }
              <StyledSubLink to="/Help" style={{ textDecoration: `none` }} activeStyle={{ textDecoration: `none` }}>FAQ's</StyledSubLink>
            </NavDropdown>
          </Nav>
          
          </Navbar.Collapse>
      </Container>
     { 
           <Container>
            <InputGroup>
                 <FormControl
                   name="searchterms"
                   placeholder="Start searching ..."
                   aria-label="Search Bar"
                   aria-describedby="basic-addon2"
                   // onChange={handleInputChange}
                   onChange={e => handleInputChange(e)}
                   type="text"
                   value={appsContext.state.searchform.terms}
                 />
     
                 <DropdownButton
                   as={InputGroup.Append}
                   variant="outline-secondary"
                   title={appsContext.state.searchform.category}
                   id="searchcategory"
                   drop="left"
                 >
                   <Dropdown.Item href="/Search" name="AllVideos" onClick={submitSearch}>All Videos</Dropdown.Item>
                   <Dropdown.Item href="/Search" name="RedCarpetEvents" onClick={submitSearch}>Red Carpet</Dropdown.Item>
                   <Dropdown.Item href="/Search" name="CelebrityCandids" onClick={submitSearch}>Paparazzi</Dropdown.Item>
                   <Dropdown.Item href="/Search" name="Music" onClick={submitSearch}>Music</Dropdown.Item>
                   <Dropdown.Divider />
                   <Dropdown.Item href="/Search" name="News" onClick={submitSearch}>News</Dropdown.Item>
                   <Dropdown.Item href="/Search" name="Sports" onClick={submitSearch}>Sports</Dropdown.Item>             
                   <Dropdown.Item href="/Search" name="Travel" onClick={submitSearch}>Travel</Dropdown.Item>
                   <Dropdown.Item href="/Search" name="Nature" onClick={submitSearch}>Nature</Dropdown.Item>
                   <Dropdown.Item href="/Search" name="Arts" onClick={submitSearch}>Arts</Dropdown.Item>
                   
                 </DropdownButton>
               </InputGroup>
               </Container>
               }
    </StyledNavbar>
  )
}

NavMain.propTypes = propTypes

export default NavMain
