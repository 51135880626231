import React from 'react'
import Amplify from "@aws-amplify/core";
import { Cache, Hub } from 'aws-amplify';
// import Analytics from '@aws-amplify/analytics';
import { Layout as ThemeLayout, Main, Footer, jsx } from 'theme-ui'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Toast from 'react-bootstrap/Toast'
import { Link } from 'gatsby'
import NavMain from './NavMain'

import '../css/theme.scss'

import { AppsContext } from '../../../AppsProvider'

// import { Authenticator, SignIn, SignUp, ConfirmSignUp, Greetings, ForgotPassword, RequireNewPassword } from 'aws-amplify-react';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp, AmplifySignOut, AmplifyConfirmSignUp, AmplifyGreetings, AmplifyForgotPassword, AmplifyRequireNewPassword, AmplifyConfirmSignIn } from '@aws-amplify/ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/free-solid-svg-icons";


import awsconfig from "../../../aws-exports";
Amplify.configure(awsconfig);
  
const colorWhite50 = "rgba(255,255,255, .5)"
const colorBlack10 = "rgba(0,0,0, .1)"
const colorBlack50 = "rgba(0,0,0, .5)"
const colorBlack80 = "rgba(0,0,0, .8)"
const colorWhiteHover = "rgba(255,255,255, .7)"
const colorSubLinkBackground = "rgba(0,0,0, .1)"

//colors in theme.js
const shotwireGray = `${props => props.theme.colors.gray}` 
// const buttonColor = `${props => props.theme.colors.shotwireButtonBlue}` //"#1b2f63"
const homepageBgColor = `${props => props.theme.colors.primary}` //"#1b2f63"

const MyTheme = {

    signInButtonIcon: { 'display': 'none' },
    googleSignInButton: { 'backgroundColor': '#1b2f63', 'borderColor': '#1b2f63' },
    button: { 'backgroundColor': '#1b2f63', 'border': '1px solid #1b2f63' },
    navButton: { 'backgroundColor': '#1b2f63', 'borderColor': '#1b2f63' },
    container: {
		// flex: 1,
		// flexDirection: 'column',
		// alignItems: 'center',
		// justifyContent: 'space-around',
		// paddingTop: 20,
		width: '100%',
		backgroundColor: '#1b2f63',
	}


}



const redButton = {
	marginLeft: '20px',
	padding: '12px 45px', 
	backgroundColor: 'red',
	color: 'white',
	fontSize: '.75rem',
	cursor: 'pointer',
	border: '1px solid red'
}

const shotwireGrayButton = {
	
	marginLeft: '20px',
	padding: '12px 45px', 
	backgroundColor: `${shotwireGray}`,
	color: '#1b2f63',
	fontSize: '.75rem',
	cursor: 'pointer',
	border: `1px solid ${shotwireGray}`
}

const linkStyle = {
	// all: 'initial',
	color: colorWhite50,
	padding: '.5rem 0',
	margin: '0 .4rem 0 0',
	boxShadow: 'none',
	textDecoration: 'none',

}

const footerLinkStyle = {
	// all: 'initial',
	color: colorWhite50,
	padding: '.5rem 0',
	margin: '0 .4rem 0 0',
	boxShadow: 'none',
	textDecoration: 'none',

}

const footerWhiteLinkStyle = {
	// all: 'initial',
	color: '#FFFFFF',
	padding: '.5rem 0',
	margin: '0 .4rem 0 0',
	boxShadow: 'none',
	textDecoration: 'none',

}



const Layout = ({ children }) => {
	const appsContext = React.useContext(AppsContext)
	let uRoles = null, uGroups = null

	React.useEffect(()=>{

		let isMounted = true;
		// console.log('appsContext.state.nextAuthState:', appsContext.state.nextAuthState)
		if(isMounted){
			Hub.dispatch('UI Auth', {
		            event: 'AuthStateChange',
		            message: typeof appsContext.state.nextAuthState !== 'undefined' ? appsContext.state.nextAuthState : 'loading',
		        });
		}

		

	},[appsContext.state.nextAuthState])

	// React.useEffect(()=>{

	// 	let isMounted = true;
		
	// 	if(isMounted){
	// 		console.log('appsContext.state.toasts.display:', appsContext.state.toasts.display)
	// 		// Hub.dispatch('UI Auth', {
	// 	 //            event: 'AuthStateChange',
	// 	 //            message: typeof appsContext.state.nextAuthState !== 'undefined' ? appsContext.state.nextAuthState : 'loading',
	// 	 //        });
	// 	}

		

	// },[appsContext.state.toasts.display])

	React.useEffect(()=>{
			let isMounted = true;
			
			const AuthListener = () => {
		      Hub.listen("UI Auth", data => {
		      	// console.log('UI Auth Hub Listerner:', data)
		        const { payload } = data;
		        onUiAuthEvent(payload);
		      });
		    };

		    const onUiAuthEvent = payload => {
		    // console.log('payload.event: ', payload.event)
		      if(payload.event === 'AuthStateChange'){
		      		switch (payload.message) {
	  			        case "loading":
	  			          if (isMounted) {
	  			            // setTriggerFetch(true);
	  			            // console.log("UI hub loading");
	  			            //hide close button
	  			            appsContext.dispatch({type:"BUTTONCLOSEAUTHUI", authUiCloseButton:{show:false}})
	  			          }
	  			          break;
	  			        case "signin":
	  			          if (isMounted) {
	  			            // setTriggerFetch(true);
	  			            // console.log("UI hub sign in");
	  			            //show close button
	  			            appsContext.dispatch({type:"BUTTONCLOSEAUTHUI", authUiCloseButton:{show:true}})
	  			          }
	  			          break;
	  			        case "signup":
	  			          if (isMounted) {
	  			            // setTriggerFetch(false);
	  			            // console.log("UI hub sign up");
	  			            // dispatch({ type: "RESET_USER_DATA" });
	  			            appsContext.dispatch({type:"BUTTONCLOSEAUTHUI", authUiCloseButton:{show:true}})
	  			          }
	  			          break;
	  			        default:
	  			          return;
  			      }
  			  }
		    };

		    AuthListener()
		    return () => {
		      Hub.remove("UI Auth");
		      isMounted = false;
		    };

		},[])

	const CustomGreetings = () => {
		// console.log('props->', props)
		// console.log('appsContext.state.user: ', appsContext.state.user)
	if(appsContext.state.authState === 'signedin' && appsContext.state.user){
		const  userInfo  = appsContext.state.user
		// console.log('userInfo: ', userInfo)

		if(typeof userInfo.signInUserSession.idToken.payload[`cognito:groups`] != 'undefined'){
			uGroups = userInfo.signInUserSession.idToken.payload[`cognito:groups`]
			// console.log('uGroups:',uGroups)
		}

		if(typeof userInfo.attributes[`custom:roles`] != 'undefined'){
			uRoles = userInfo.attributes[`custom:roles`].split(',')
			// console.log('uGroups:',uGroups)
		}
		
		// console.log('uRoles: ', uRoles)


		// const adminLink = (uRoles == 'superAdmin') ? 
		// 	<Link to="/ControlCenter" style={{ textDecoration: `none` }} activeStyle={{ color: `#FFF`, textDecoration: `underline` }}>Control Center</Link>
		// 	: <span></span>
		
		return (
			
			<Container>
			<Row>
			<Col xs={{span:12, order:2}} sm={{span:12, order:2}} lg={{span:8, order:1}} style={{padding: '10px', textAlign: 'left'}} >
			{
				(userInfo.username.length) 
					? <Link to="/Profile" style={{ color: colorWhite50, textDecoration: `none` }} activeStyle={{ color: '#FFF', textDecoration: `none`, borderBottom: '3px solid #FFF' }}>Profile</Link>
					: <span></span>
			}
			{'  -  '}
			{//arr1.some(r=> arr2.indexOf(r) >= 0)
				(uGroups != null) ?  
					(
										(uGroups.some(r => ['Uploaders','Admins','Editors'].indexOf(r) >= 0)) 
										? <Link to="/ControlCenter" style={{  color: colorWhite50, textDecoration: `none` }} activeStyle={{  color: '#FFF', textDecoration: `none`, borderBottom: '3px solid #FFF' }}>Video Center</Link>
										: <span></span>
					)
					: <span></span>
			}
			{'  -  '}
			{//arr1.some(r=> arr2.indexOf(r) >= 0)
				(uGroups != null) ?  
					(
										(uGroups.some(r => ['Uploaders','Admins','Editors'].indexOf(r) >= 0)) 
										? <Link to="/PhotoCenter" style={{  color: colorWhite50, textDecoration: `none` }} activeStyle={{  color: '#FFF', textDecoration: `none`, borderBottom: '3px solid #FFF' }}>Photo Center</Link>
										: <span></span>
					)
					: <span></span>
			}
			{'  -  '}
			{//arr1.some(r=> arr2.indexOf(r) >= 0)
				(uGroups != null) ? 
					(
										(uGroups.some(r => ['Admins'].indexOf(r) >= 0)) 
										? <Link to="/AdminQrs" style={{  color: colorWhite50, textDecoration: `none` }} activeStyle={{  color: '#FFF', textDecoration: `none`, borderBottom: '3px solid #FFF' }}>Admin Center</Link>
										: <span></span>
					)
					: <span></span>
			}
			{'  -  '}
			{//arr1.some(r=> arr2.indexOf(r) >= 0)
				(uGroups != null) ? 
					(
										(uGroups.some(r => ['Admins'].indexOf(r) >= 0)) 
										? <Link to="/XeroAppDev" style={{  color: colorWhite50, textDecoration: `none` }} activeStyle={{  color: '#FFF', textDecoration: `none`, borderBottom: '3px solid #FFF' }}>Xero App</Link>
										: <span></span>
					)
					: <span></span>
			}
			
			</Col>
			<Col xs={{span:12, order:1}} sm={{span:12, order:1}} lg={{span:4, order:2}} style={{color: colorWhite50 , textAlign: 'right'}}>
			Hello {userInfo.username} <button style={ shotwireGrayButton } onClick={() => appsContext.handleSignout() }>SIGN OUT</button>
            </Col>
            </Row>
			</Container>
		)
	}else return (<div></div>)
}

const CloseButton = () => {
	// console.log('closebutton props:', props.authState)
	// console.log('authUiCloseButton:',appsContext.state.authUiCloseButton)
	// if(appsContext.state.user)
	if(appsContext.state.authUiCloseButton.show === true){
		// console.log('close button...')
			// if(props.authState === 'signin' || props.authState === 'signup') 
			return (
				<Container style={{
		              // padding: '5px', 
		              // backgroundColor: 'pink',
		              textAlign: 'right',
		              maxWidth: '450px'
		            }}>
				<Row>
					<Col sm={12} lg={12} style={{padding: '10px'}} >
					<Button style={{ textAlign: 'right', position: 'relative', top: '50px', left: '10px', zIndex: '1000' }} variant="dark" size="md" onClick={() => dispatchAuthStateChangeEvent('loading')}>
		                <FontAwesomeIcon
		                  icon={faTimes}
		                  size="lg"
		                  sx={{
		                    marginRight: '0.5rem'
		                  }}
		                />
		            </Button>
		            </Col>
		            </Row>
		         </Container>
		
				)
			// else return(<div></div>)
		}
		else return(<div></div>)
	 
}

const AlwaysOn = () => {
    // console.log('authState->', props.authState)
    // console.log('appsContext.state.authState,', appsContext.state.authState)
      // if(appsContext.state.authState === 'signedin') return (<div></div>)
      if(appsContext.state.authState === 'signedin' && appsContext.state.user) return (<div></div>)
      else{
	         // console.log('sign in buttons')
	         if(appsContext.state.hideSignInBar === false){
                  return (
                    	<Container>
          			<Row>
          			<Col xs={{span:12, order:2}} sm={{span:12, order:2}} lg={{span:12, order:1}} style={{padding: '10px'}} >
                                  <strong></strong>
                                  {' '}<a style={ linkStyle } href='' onClick={(e) => {e.preventDefault();dispatchAuthStateChangeEvent('signup')}}>Sign up</a>
                                  {' '}<a style={ linkStyle } href='' onClick={(e) => {e.preventDefault();dispatchAuthStateChangeEvent('signin')}}>Log in</a>
                              </Col>
                              </Row>
                              </Container>
                              )
             }else{
             	return (<div></div>)
             }
       }
      	//{' '}<a style={ linkStyle } onClick={() => props.onStateChange('loading')}>Cancel</a>
      	// {' '}<button sx={{border:`grey 1px solid`, backgroundColor:`grey`, color: `white`}} onClick={() => props.onStateChange('signUp')}>Sign Up</button>
       //        {' '}<button sx={{border:`grey 1px solid`, backgroundColor:`grey`, color: `white`}}  onClick={() => props.onStateChange('signIn')}>Log In</button>
       //        {' '}<button sx={{border:`grey 1px solid`, backgroundColor:`grey`, color: `white`}}  onClick={() => props.onStateChange('loading')}>Cancel</button>
  }


  const ErrorDisplay = () => {
  	// if(typeof props.errorMessage != 'undefined'){
  	// if(props.authState == 'signedIn'){
  		return(
  			<Alert variant="danger">
  			{appsContext.state.authErrorMessage}
	    	</Alert>
  			
  			)
  	// }else return(<div></div>)

  }

  const Toasts = () => {
  
  	// const [show, setShow] = useState(false);

	return (
			<div
				  aria-live="polite"
				  aria-atomic="true"
				  style={{
				    position: 'relative',
				    minHeight: '200px',
				    minWidth: '350px',
				    display: appsContext.state.toasts.display
				  }}
			>
			<div
			    style={{
			      position: 'absolute',
			      top: appsContext.state.toasts.displayTopPosition,
			      left: 50,
			      minWidth: '350px',
			      // display: appsContext.state.toasts.display
			    }}
			>
		    <Row>
		      <Col xs={6}>
		        <Toast onClose={() => appsContext.dispatch({type:"TOASTS", toasts:{ show:false, display:'none' }})} show={appsContext.state.toasts.show} delay={4000} autohide>
		          <Toast.Header style={{backgroundColor:'red'}}>
		            {
		            // <img
		            //   src="holder.js/20x20?text=%20"
		            //   className="rounded mr-2"
		            //   alt=""
		            // />
		        	}
		            <strong className="mr-auto" style={{color:'black'}}>{(appsContext.state.toasts.title)}</strong>
		            <small>{appsContext.state.toasts.time}</small>
		          </Toast.Header>
		          <Toast.Body>{appsContext.state.toasts.body}</Toast.Body>
		        </Toast>
		      </Col>
		    </Row>
		   </div>
	   </div>
	  );
	// <Col xs={6}>
	// 	        <Button onClick={() => appsContext.dispatch({ type:"TOASTS", toasts:{ show:true }})}>Show Toast</Button>
	// 	      </Col>
	}

	const errMap = (message) => {
		console.log('auth err message:', message)
	    // if (/incorrect.*username.*password/i.test(message)) {
	    // 	// console.log('Incorrect username or password')
	    //     return 'Incorrect username or password';
	    // }else if(/Username.*not.*found/i.test(message)){
	    // 	return 'Username not found';
	    // }else if(/User.*not.*exist/i.test(message)){
	    // 	return 'Username does not exist';
	    // }

	    // window.alert(message);
	    appsContext.dispatch({ type:"TOASTS", toasts:{ show:true, display:'block', displayTopPosition:'-400px', title:"Error", body: message }})
	    
	}

	// const handleAuthStateChange = (state) => {
	// 	console.log('appsContext.state.authState:', appsContext.state.authState)
 //    	// console.log('authState:', state)

 //      if (state === 'signedin') {
 //          /* Do something when the user has signed-in */
 //          console.log('state signedin!')

 //      }else if(state === 'signin'){
 //      	console.log('state signin.')
 //      }else if(state === 'signout'){
 //      	console.log('state signOut.')
 //      	appsContext.handleSignout()

 //      }else if(state === 'signedout'){
 //      	console.log('state signedOut.')
 //      	appsContext.handleSignout()

 //      }else if(state === 'signup'){
 //      	console.log('state signup.')
 //      	// appsContext.handleSignout()

 //      }
      
 //  	}

  	const dispatchAuthStateChangeEvent = (nextAuthState) => {
  		// console.log('nextAuthState',nextAuthState)
        
		appsContext.dispatch({type:"AUTHSTATECHANGEEVENT", nextAuthState:nextAuthState})
        // Hub.dispatch('UI Auth', {
        //     event: 'AuthStateChange',
        //     message: nextAuthState,
        // });
    };

    const Loading = ({ children }) => {
	    if(appsContext.state.authState === 'signedin') return null
	    return <div slot="loading">{children}</div>
	  }

  // const signUpConfig = {
  //   hiddenDefaults: [
  //     'username',
  //     'email',
  //     'phone_number',
  //     'password'
  //   ],
  //   signUpFields: [
  //     {
  //       label: 'Password',
  //       key: 'password',
  //       required: true,
  //       type: 'password'
  //     },
  //     {
  //       label: 'Email',
  //       key: 'email',
  //       required: true,
  //       type: 'string'
  //     },
  //     {
  //       label: 'Name',
  //       key: 'name',
  //       required: true,
  //       type: 'string'
  //     },
  //     {
  //       label: 'Username',
  //       key: 'username',
  //       required: true,
  //       type: 'string'
  //     },
  //   ]
  // }

 //  const signUpConfig = {
	//   header: 'My Customized Sign Up',
	//   hideAllDefaults: true,
	//   defaultCountryCode: '1',
	//   hiddenDefaults:['phone_number'],
	//   signUpFields: [
	//     {
	//       label: 'My custom email label',
	//       key: 'email',
	//       required: true,
	//       displayOrder: 3,
	//       type: 'string'
	//     },
	//     {
	//         label: 'Username',
	//         key: 'username',
	//         required: true,
	//         displayOrder: 1,
	//         type: 'string'
	//       },
	//       {
	//         label: 'Password',
	//         key: 'password',
	//         required: true,
	//         displayOrder: 2,
	//         type: 'password'
	//       }
	//   ]
	// };


// <div style={{maxWidth: '1024px' ,padding: '5px', textAlign: 'center', backgroundColor: 'white'}}>
  	return (	 
	    <ThemeLayout>
	      <NavMain />
	      <div style={{ 
	      	width: '100%',
	      	color:'#1b2f63',
	      	textAlign: 'center',
	      	height: 'auto',
	      	// height: '200px', 
	      	// border: '1px solid red',
	      	backgroundColor: '#1b2f63'
	      }}>
	      <AlwaysOn/>
	      <CloseButton/>
	      <CustomGreetings /> 
	      <AmplifyAuthenticator 
	      	// signUpConfig = { signUpConfig }
	      	// signUpConfig={ {hiddenDefaults:['phone_number']} }
	      	// formFields={['username', 'email', 'password']}
            errorMessage={ errMap }
            initialAuthState='loading'
            hideDefault={ true } 
            handleAuthStateChange={dispatchAuthStateChangeEvent}
            // theme={ MyTheme }
       //      hide={ 
			    //     [
			    //         // Greetings,
			    //         // SignIn,
			    //         // ConfirmSignIn,
			    //         // RequireNewPassword,
			    //         // SignUp,
			    //         // ConfirmSignUp,
			    //         // VerifyContact,
			    //         // ForgotPassword,
			    //         // TOTPSetup,
			    //         Loading
			    //     ]
			    // }
            >                   
            

            {
            // <AmplifyGreetings username={userInfo.username}/>
            <Loading slot="loading" handleAuthStateChange={dispatchAuthStateChangeEvent} />
            }
            
            
            <AmplifySignIn headerText="Shotwire Login" slot="sign-in" handleAuthStateChange={dispatchAuthStateChangeEvent} />
            <AmplifySignUp 
            	headerText="Shotwire Sign Up" 
            	slot="sign-up" 
            	formFields={[
				        {
				          type: 'username',
				          label: 'Username',
				          placeholder: 'Enter your username',
				          required: true,
				          handleInputChange: (event, cb) => {
				            // console.log('custom username field');
				            //perform any actions needed
				            cb(event);
				          },
				        },
				        {
				          type: 'password',
				          label: 'Password',
				          placeholder: 'Enter your password',
				          required: true,
				          handleInputChange: (event, cb) => {
				            // console.log('custom pwd field');
				            cb(event);
				          },
				        },
				        {
				          type: 'email',
				          label: 'Email',
				          placeholder: 'Enter a valid email address',
				          required: true,
				          handleInputChange: (event, cb) => {
				            // console.log('custom email field');
				            cb(event);
				          },
				        },
				      ]}
					handleAuthStateChange={dispatchAuthStateChangeEvent}
            	/>
            {
            // <AmplifySignOut buttonText="Log out" handleAuthStateChange={handleAuthStateChange}/>
            }
            {
            	(appsContext.state.authState === 'signedin' && appsContext.state.user) ? '':
            	<AmplifyConfirmSignIn 
                        	headerText="Confirm Shotwire Verification Code" 
                        	slot="confirm-sign-in"
                        	user={appsContext.state.user}
                        ></AmplifyConfirmSignIn>
                    }
            <AmplifyRequireNewPassword headerText="Enter New Shotwire Password" slot="require-new-password"></AmplifyRequireNewPassword>
            <AmplifyForgotPassword headerText="Reset Your Shotwire Password" slot="forgot-password"></AmplifyForgotPassword>
          </AmplifyAuthenticator>
       	  </div>

	      <Main style={{
	      	// backgroundColor:shotwireGray
	      	backgroundColor:appsContext.state.theme.bodyBgColor,
	      	color:appsContext.state.theme.bodyTextColor
	      }}>
	      {children}
	      </Main>
	      <Footer style={{ backgroundColor:colorBlack80, padding:'10px', width: '100%'}}>
	      <Row style={{width:'100%'}}>
	      <Col sm={6}>
	      <Link to="/Cloud" style={ footerLinkStyle } activeStyle={{ color: `#FFF`, textDecoration: `underline` }}>Powered by Shotwire</Link>
	      </Col>
	      <Col sm={6} style={{textAlign:'right'}}>
	      </Col>
	      </Row>
	      </Footer>
	    </ThemeLayout>
	  )

}

export default Layout
